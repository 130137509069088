/* tslint:disable */
/* eslint-disable */
/**
 * labatAsia-remote-consultation
 * labatAsia-remote-consultation API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ConsultationHistoryList } from '../models';
// @ts-ignore
import { ConsultationRecordingsLogs } from '../models';
// @ts-ignore
import { ConsultationRequestAudiologist } from '../models';
// @ts-ignore
import { ConsultationRequestCreate } from '../models';
// @ts-ignore
import { ConsultationRequestGet } from '../models';
// @ts-ignore
import { ConsultationRequestList } from '../models';
// @ts-ignore
import { ConsultationRequestStatus } from '../models';
// @ts-ignore
import { ConsultationResponse } from '../models';
// @ts-ignore
import { HTTPValidationError } from '../models';
/**
 * ConsultationrequestsApi - axios parameter creator
 * @export
 */
export const ConsultationrequestsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Accept Consultation
         * @param {number} consultationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        acceptConsultation: async (consultationId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'consultationId' is not null or undefined
            assertParamExists('acceptConsultation', 'consultationId', consultationId)
            const localVarPath = `/api/v1/consultationrequests/accept`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (consultationId !== undefined) {
                localVarQueryParameter['consultation_id'] = consultationId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Cancel Consultation
         * @param {number} consultationId 
         * @param {string} cancelledReason 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelConsultation: async (consultationId: number, cancelledReason: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'consultationId' is not null or undefined
            assertParamExists('cancelConsultation', 'consultationId', consultationId)
            // verify required parameter 'cancelledReason' is not null or undefined
            assertParamExists('cancelConsultation', 'cancelledReason', cancelledReason)
            const localVarPath = `/api/v1/consultationrequests/cancel`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (consultationId !== undefined) {
                localVarQueryParameter['consultation_id'] = consultationId;
            }

            if (cancelledReason !== undefined) {
                localVarQueryParameter['cancelled_Reason'] = cancelledReason;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Close Consultation
         * @param {number} testId 
         * @param {string} remarks 
         * @param {string} remarksRight 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        closeConsultation: async (testId: number, remarks: string, remarksRight: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'testId' is not null or undefined
            assertParamExists('closeConsultation', 'testId', testId)
            // verify required parameter 'remarks' is not null or undefined
            assertParamExists('closeConsultation', 'remarks', remarks)
            // verify required parameter 'remarksRight' is not null or undefined
            assertParamExists('closeConsultation', 'remarksRight', remarksRight)
            const localVarPath = `/api/v1/consultationrequests/close`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (testId !== undefined) {
                localVarQueryParameter['test_id'] = testId;
            }

            if (remarks !== undefined) {
                localVarQueryParameter['remarks'] = remarks;
            }

            if (remarksRight !== undefined) {
                localVarQueryParameter['remarks_right'] = remarksRight;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Close Consultation By Centre
         * @param {number} consultationId 
         * @param {string} remarks 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        closeConsultationByCentre: async (consultationId: number, remarks: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'consultationId' is not null or undefined
            assertParamExists('closeConsultationByCentre', 'consultationId', consultationId)
            // verify required parameter 'remarks' is not null or undefined
            assertParamExists('closeConsultationByCentre', 'remarks', remarks)
            const localVarPath = `/api/v1/consultationrequests/centre/close`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (consultationId !== undefined) {
                localVarQueryParameter['consultation_id'] = consultationId;
            }

            if (remarks !== undefined) {
                localVarQueryParameter['remarks'] = remarks;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create Consultation
         * @param {ConsultationRequestCreate} consultationRequestCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createConsultation: async (consultationRequestCreate: ConsultationRequestCreate, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'consultationRequestCreate' is not null or undefined
            assertParamExists('createConsultation', 'consultationRequestCreate', consultationRequestCreate)
            const localVarPath = `/api/v1/consultationrequests`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(consultationRequestCreate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Download Recording
         * @param {number} consultationUid 
         * @param {string} filename 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadRecording: async (consultationUid: number, filename: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'consultationUid' is not null or undefined
            assertParamExists('downloadRecording', 'consultationUid', consultationUid)
            // verify required parameter 'filename' is not null or undefined
            assertParamExists('downloadRecording', 'filename', filename)
            const localVarPath = `/api/v1/consultationrequests/recording/download`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (consultationUid !== undefined) {
                localVarQueryParameter['consultation_uid'] = consultationUid;
            }

            if (filename !== undefined) {
                localVarQueryParameter['filename'] = filename;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Centre Current Consultation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCentreCurrentConsultation: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/consultationrequests/centre/current`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Consultation Audilogistinfo
         * @param {number} consultationId 
         * @param {string} [sort] Format: &#x60;[\&quot;field_name\&quot;, \&quot;direction\&quot;]&#x60;
         * @param {string} [range] Format: &#x60;[start, end]&#x60;
         * @param {string} [filter] Format: &#x60;{\&quot;filter_field\&quot;:\&quot;filter_value\&quot;}&#x60;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConsultationAudilogistinfo: async (consultationId: number, sort?: string, range?: string, filter?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'consultationId' is not null or undefined
            assertParamExists('getConsultationAudilogistinfo', 'consultationId', consultationId)
            const localVarPath = `/api/v1/consultationrequests/{consultation_id}/audilogistinfo`
                .replace(`{${"consultation_id"}}`, encodeURIComponent(String(consultationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (range !== undefined) {
                localVarQueryParameter['range'] = range;
            }

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Consultationrequests
         * @param {string} [sort] Format: &#x60;[\&quot;field_name\&quot;, \&quot;direction\&quot;]&#x60;
         * @param {string} [range] Format: &#x60;[start, end]&#x60;
         * @param {string} [filter] Format: &#x60;{\&quot;filter_field\&quot;:\&quot;filter_value\&quot;}&#x60;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConsultationRequests: async (sort?: string, range?: string, filter?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/consultationrequests`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (range !== undefined) {
                localVarQueryParameter['range'] = range;
            }

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Consultations History
         * @param {string} fromDate 
         * @param {string} toDate 
         * @param {string} [sort] Format: &#x60;[\&quot;field_name\&quot;, \&quot;direction\&quot;]&#x60;
         * @param {string} [range] Format: &#x60;[start, end]&#x60;
         * @param {string} [filter] Format: &#x60;{\&quot;filter_field\&quot;:\&quot;filter_value\&quot;}&#x60;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConsultationsHistory: async (fromDate: string, toDate: string, sort?: string, range?: string, filter?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fromDate' is not null or undefined
            assertParamExists('getConsultationsHistory', 'fromDate', fromDate)
            // verify required parameter 'toDate' is not null or undefined
            assertParamExists('getConsultationsHistory', 'toDate', toDate)
            const localVarPath = `/api/v1/consultationrequests/history/audiologist`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (fromDate !== undefined) {
                localVarQueryParameter['from_date'] = (fromDate as any instanceof Date) ?
                    (fromDate as any).toISOString() :
                    fromDate;
            }

            if (toDate !== undefined) {
                localVarQueryParameter['to_date'] = (toDate as any instanceof Date) ?
                    (toDate as any).toISOString() :
                    toDate;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (range !== undefined) {
                localVarQueryParameter['range'] = range;
            }

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Consultations History Centre
         * @param {string} fromDate 
         * @param {string} toDate 
         * @param {string} [sort] Format: &#x60;[\&quot;field_name\&quot;, \&quot;direction\&quot;]&#x60;
         * @param {string} [range] Format: &#x60;[start, end]&#x60;
         * @param {string} [filter] Format: &#x60;{\&quot;filter_field\&quot;:\&quot;filter_value\&quot;}&#x60;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConsultationsHistoryCentre: async (fromDate: string, toDate: string, sort?: string, range?: string, filter?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fromDate' is not null or undefined
            assertParamExists('getConsultationsHistoryCentre', 'fromDate', fromDate)
            // verify required parameter 'toDate' is not null or undefined
            assertParamExists('getConsultationsHistoryCentre', 'toDate', toDate)
            const localVarPath = `/api/v1/consultationrequests/history/centre`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (fromDate !== undefined) {
                localVarQueryParameter['from_date'] = (fromDate as any instanceof Date) ?
                    (fromDate as any).toISOString() :
                    fromDate;
            }

            if (toDate !== undefined) {
                localVarQueryParameter['to_date'] = (toDate as any instanceof Date) ?
                    (toDate as any).toISOString() :
                    toDate;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (range !== undefined) {
                localVarQueryParameter['range'] = range;
            }

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Current Consultation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrentConsultation: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/consultationrequests/current`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Recordings
         * @param {number} consultationUid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRecordings: async (consultationUid: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'consultationUid' is not null or undefined
            assertParamExists('getRecordings', 'consultationUid', consultationUid)
            const localVarPath = `/api/v1/consultationrequests/recordings/all`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (consultationUid !== undefined) {
                localVarQueryParameter['consultation_uid'] = consultationUid;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Patient Join Consultation
         * @param {number} consultationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patientJoinConsultation: async (consultationId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'consultationId' is not null or undefined
            assertParamExists('patientJoinConsultation', 'consultationId', consultationId)
            const localVarPath = `/api/v1/consultationrequests/patient/join/{consultation_id}`
                .replace(`{${"consultation_id"}}`, encodeURIComponent(String(consultationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Consultation Patient
         * @param {number} consultationId 
         * @param {number} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateConsultationPatient: async (consultationId: number, patientId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'consultationId' is not null or undefined
            assertParamExists('updateConsultationPatient', 'consultationId', consultationId)
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('updateConsultationPatient', 'patientId', patientId)
            const localVarPath = `/api/v1/consultationrequests/{consultation_id}/patient/{patient_id}`
                .replace(`{${"consultation_id"}}`, encodeURIComponent(String(consultationId)))
                .replace(`{${"patient_id"}}`, encodeURIComponent(String(patientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Upload Recording
         * @param {number} consultationUid 
         * @param {any} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadRecording: async (consultationUid: number, file: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'consultationUid' is not null or undefined
            assertParamExists('uploadRecording', 'consultationUid', consultationUid)
            // verify required parameter 'file' is not null or undefined
            assertParamExists('uploadRecording', 'file', file)
            const localVarPath = `/api/v1/consultationrequests/recording/upload`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (consultationUid !== undefined) {
                localVarQueryParameter['consultation_uid'] = consultationUid;
            }


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ConsultationrequestsApi - functional programming interface
 * @export
 */
export const ConsultationrequestsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ConsultationrequestsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Accept Consultation
         * @param {number} consultationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async acceptConsultation(consultationId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConsultationRequestStatus>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.acceptConsultation(consultationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Cancel Consultation
         * @param {number} consultationId 
         * @param {string} cancelledReason 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cancelConsultation(consultationId: number, cancelledReason: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConsultationRequestList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cancelConsultation(consultationId, cancelledReason, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Close Consultation
         * @param {number} testId 
         * @param {string} remarks 
         * @param {string} remarksRight 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async closeConsultation(testId: number, remarks: string, remarksRight: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.closeConsultation(testId, remarks, remarksRight, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Close Consultation By Centre
         * @param {number} consultationId 
         * @param {string} remarks 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async closeConsultationByCentre(consultationId: number, remarks: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.closeConsultationByCentre(consultationId, remarks, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create Consultation
         * @param {ConsultationRequestCreate} consultationRequestCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createConsultation(consultationRequestCreate: ConsultationRequestCreate, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConsultationRequestGet>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createConsultation(consultationRequestCreate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Download Recording
         * @param {number} consultationUid 
         * @param {string} filename 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadRecording(consultationUid: number, filename: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.downloadRecording(consultationUid, filename, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Centre Current Consultation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCentreCurrentConsultation(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConsultationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCentreCurrentConsultation(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Consultation Audilogistinfo
         * @param {number} consultationId 
         * @param {string} [sort] Format: &#x60;[\&quot;field_name\&quot;, \&quot;direction\&quot;]&#x60;
         * @param {string} [range] Format: &#x60;[start, end]&#x60;
         * @param {string} [filter] Format: &#x60;{\&quot;filter_field\&quot;:\&quot;filter_value\&quot;}&#x60;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getConsultationAudilogistinfo(consultationId: number, sort?: string, range?: string, filter?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConsultationRequestAudiologist>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getConsultationAudilogistinfo(consultationId, sort, range, filter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Consultationrequests
         * @param {string} [sort] Format: &#x60;[\&quot;field_name\&quot;, \&quot;direction\&quot;]&#x60;
         * @param {string} [range] Format: &#x60;[start, end]&#x60;
         * @param {string} [filter] Format: &#x60;{\&quot;filter_field\&quot;:\&quot;filter_value\&quot;}&#x60;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getConsultationRequests(sort?: string, range?: string, filter?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ConsultationRequestList>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getConsultationRequests(sort, range, filter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Consultations History
         * @param {string} fromDate 
         * @param {string} toDate 
         * @param {string} [sort] Format: &#x60;[\&quot;field_name\&quot;, \&quot;direction\&quot;]&#x60;
         * @param {string} [range] Format: &#x60;[start, end]&#x60;
         * @param {string} [filter] Format: &#x60;{\&quot;filter_field\&quot;:\&quot;filter_value\&quot;}&#x60;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getConsultationsHistory(fromDate: string, toDate: string, sort?: string, range?: string, filter?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ConsultationHistoryList>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getConsultationsHistory(fromDate, toDate, sort, range, filter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Consultations History Centre
         * @param {string} fromDate 
         * @param {string} toDate 
         * @param {string} [sort] Format: &#x60;[\&quot;field_name\&quot;, \&quot;direction\&quot;]&#x60;
         * @param {string} [range] Format: &#x60;[start, end]&#x60;
         * @param {string} [filter] Format: &#x60;{\&quot;filter_field\&quot;:\&quot;filter_value\&quot;}&#x60;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getConsultationsHistoryCentre(fromDate: string, toDate: string, sort?: string, range?: string, filter?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ConsultationRequestList>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getConsultationsHistoryCentre(fromDate, toDate, sort, range, filter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Current Consultation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCurrentConsultation(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConsultationRequestList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCurrentConsultation(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Recordings
         * @param {number} consultationUid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRecordings(consultationUid: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ConsultationRecordingsLogs>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRecordings(consultationUid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Patient Join Consultation
         * @param {number} consultationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patientJoinConsultation(consultationId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConsultationRequestList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patientJoinConsultation(consultationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update Consultation Patient
         * @param {number} consultationId 
         * @param {number} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateConsultationPatient(consultationId: number, patientId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateConsultationPatient(consultationId, patientId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Upload Recording
         * @param {number} consultationUid 
         * @param {any} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadRecording(consultationUid: number, file: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadRecording(consultationUid, file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ConsultationrequestsApi - factory interface
 * @export
 */
export const ConsultationrequestsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ConsultationrequestsApiFp(configuration)
    return {
        /**
         * 
         * @summary Accept Consultation
         * @param {number} consultationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        acceptConsultation(consultationId: number, options?: any): AxiosPromise<ConsultationRequestStatus> {
            return localVarFp.acceptConsultation(consultationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Cancel Consultation
         * @param {number} consultationId 
         * @param {string} cancelledReason 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelConsultation(consultationId: number, cancelledReason: string, options?: any): AxiosPromise<ConsultationRequestList> {
            return localVarFp.cancelConsultation(consultationId, cancelledReason, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Close Consultation
         * @param {number} testId 
         * @param {string} remarks 
         * @param {string} remarksRight 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        closeConsultation(testId: number, remarks: string, remarksRight: string, options?: any): AxiosPromise<any> {
            return localVarFp.closeConsultation(testId, remarks, remarksRight, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Close Consultation By Centre
         * @param {number} consultationId 
         * @param {string} remarks 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        closeConsultationByCentre(consultationId: number, remarks: string, options?: any): AxiosPromise<any> {
            return localVarFp.closeConsultationByCentre(consultationId, remarks, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create Consultation
         * @param {ConsultationRequestCreate} consultationRequestCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createConsultation(consultationRequestCreate: ConsultationRequestCreate, options?: any): AxiosPromise<ConsultationRequestGet> {
            return localVarFp.createConsultation(consultationRequestCreate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Download Recording
         * @param {number} consultationUid 
         * @param {string} filename 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadRecording(consultationUid: number, filename: string, options?: any): AxiosPromise<any> {
            return localVarFp.downloadRecording(consultationUid, filename, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Centre Current Consultation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCentreCurrentConsultation(options?: any): AxiosPromise<ConsultationResponse> {
            return localVarFp.getCentreCurrentConsultation(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Consultation Audilogistinfo
         * @param {number} consultationId 
         * @param {string} [sort] Format: &#x60;[\&quot;field_name\&quot;, \&quot;direction\&quot;]&#x60;
         * @param {string} [range] Format: &#x60;[start, end]&#x60;
         * @param {string} [filter] Format: &#x60;{\&quot;filter_field\&quot;:\&quot;filter_value\&quot;}&#x60;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConsultationAudilogistinfo(consultationId: number, sort?: string, range?: string, filter?: string, options?: any): AxiosPromise<ConsultationRequestAudiologist> {
            return localVarFp.getConsultationAudilogistinfo(consultationId, sort, range, filter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Consultationrequests
         * @param {string} [sort] Format: &#x60;[\&quot;field_name\&quot;, \&quot;direction\&quot;]&#x60;
         * @param {string} [range] Format: &#x60;[start, end]&#x60;
         * @param {string} [filter] Format: &#x60;{\&quot;filter_field\&quot;:\&quot;filter_value\&quot;}&#x60;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConsultationRequests(sort?: string, range?: string, filter?: string, options?: any): AxiosPromise<Array<ConsultationRequestList>> {
            return localVarFp.getConsultationRequests(sort, range, filter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Consultations History
         * @param {string} fromDate 
         * @param {string} toDate 
         * @param {string} [sort] Format: &#x60;[\&quot;field_name\&quot;, \&quot;direction\&quot;]&#x60;
         * @param {string} [range] Format: &#x60;[start, end]&#x60;
         * @param {string} [filter] Format: &#x60;{\&quot;filter_field\&quot;:\&quot;filter_value\&quot;}&#x60;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConsultationsHistory(fromDate: string, toDate: string, sort?: string, range?: string, filter?: string, options?: any): AxiosPromise<Array<ConsultationHistoryList>> {
            return localVarFp.getConsultationsHistory(fromDate, toDate, sort, range, filter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Consultations History Centre
         * @param {string} fromDate 
         * @param {string} toDate 
         * @param {string} [sort] Format: &#x60;[\&quot;field_name\&quot;, \&quot;direction\&quot;]&#x60;
         * @param {string} [range] Format: &#x60;[start, end]&#x60;
         * @param {string} [filter] Format: &#x60;{\&quot;filter_field\&quot;:\&quot;filter_value\&quot;}&#x60;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConsultationsHistoryCentre(fromDate: string, toDate: string, sort?: string, range?: string, filter?: string, options?: any): AxiosPromise<Array<ConsultationRequestList>> {
            return localVarFp.getConsultationsHistoryCentre(fromDate, toDate, sort, range, filter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Current Consultation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrentConsultation(options?: any): AxiosPromise<ConsultationRequestList> {
            return localVarFp.getCurrentConsultation(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Recordings
         * @param {number} consultationUid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRecordings(consultationUid: number, options?: any): AxiosPromise<Array<ConsultationRecordingsLogs>> {
            return localVarFp.getRecordings(consultationUid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Patient Join Consultation
         * @param {number} consultationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patientJoinConsultation(consultationId: number, options?: any): AxiosPromise<ConsultationRequestList> {
            return localVarFp.patientJoinConsultation(consultationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Consultation Patient
         * @param {number} consultationId 
         * @param {number} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateConsultationPatient(consultationId: number, patientId: number, options?: any): AxiosPromise<any> {
            return localVarFp.updateConsultationPatient(consultationId, patientId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Upload Recording
         * @param {number} consultationUid 
         * @param {any} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadRecording(consultationUid: number, file: any, options?: any): AxiosPromise<any> {
            return localVarFp.uploadRecording(consultationUid, file, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for acceptConsultation operation in ConsultationrequestsApi.
 * @export
 * @interface ConsultationrequestsApiAcceptConsultationRequest
 */
export interface ConsultationrequestsApiAcceptConsultationRequest {
    /**
     * 
     * @type {number}
     * @memberof ConsultationrequestsApiAcceptConsultation
     */
    readonly consultationId: number
}

/**
 * Request parameters for cancelConsultation operation in ConsultationrequestsApi.
 * @export
 * @interface ConsultationrequestsApiCancelConsultationRequest
 */
export interface ConsultationrequestsApiCancelConsultationRequest {
    /**
     * 
     * @type {number}
     * @memberof ConsultationrequestsApiCancelConsultation
     */
    readonly consultationId: number

    /**
     * 
     * @type {string}
     * @memberof ConsultationrequestsApiCancelConsultation
     */
    readonly cancelledReason: string
}

/**
 * Request parameters for closeConsultation operation in ConsultationrequestsApi.
 * @export
 * @interface ConsultationrequestsApiCloseConsultationRequest
 */
export interface ConsultationrequestsApiCloseConsultationRequest {
    /**
     * 
     * @type {number}
     * @memberof ConsultationrequestsApiCloseConsultation
     */
    readonly testId: number

    /**
     * 
     * @type {string}
     * @memberof ConsultationrequestsApiCloseConsultation
     */
    readonly remarks: string

    /**
     * 
     * @type {string}
     * @memberof ConsultationrequestsApiCloseConsultation
     */
    readonly remarksRight: string
}

/**
 * Request parameters for closeConsultationByCentre operation in ConsultationrequestsApi.
 * @export
 * @interface ConsultationrequestsApiCloseConsultationByCentreRequest
 */
export interface ConsultationrequestsApiCloseConsultationByCentreRequest {
    /**
     * 
     * @type {number}
     * @memberof ConsultationrequestsApiCloseConsultationByCentre
     */
    readonly consultationId: number

    /**
     * 
     * @type {string}
     * @memberof ConsultationrequestsApiCloseConsultationByCentre
     */
    readonly remarks: string
}

/**
 * Request parameters for createConsultation operation in ConsultationrequestsApi.
 * @export
 * @interface ConsultationrequestsApiCreateConsultationRequest
 */
export interface ConsultationrequestsApiCreateConsultationRequest {
    /**
     * 
     * @type {ConsultationRequestCreate}
     * @memberof ConsultationrequestsApiCreateConsultation
     */
    readonly consultationRequestCreate: ConsultationRequestCreate
}

/**
 * Request parameters for downloadRecording operation in ConsultationrequestsApi.
 * @export
 * @interface ConsultationrequestsApiDownloadRecordingRequest
 */
export interface ConsultationrequestsApiDownloadRecordingRequest {
    /**
     * 
     * @type {number}
     * @memberof ConsultationrequestsApiDownloadRecording
     */
    readonly consultationUid: number

    /**
     * 
     * @type {string}
     * @memberof ConsultationrequestsApiDownloadRecording
     */
    readonly filename: string
}

/**
 * Request parameters for getConsultationAudilogistinfo operation in ConsultationrequestsApi.
 * @export
 * @interface ConsultationrequestsApiGetConsultationAudilogistinfoRequest
 */
export interface ConsultationrequestsApiGetConsultationAudilogistinfoRequest {
    /**
     * 
     * @type {number}
     * @memberof ConsultationrequestsApiGetConsultationAudilogistinfo
     */
    readonly consultationId: number

    /**
     * Format: &#x60;[\&quot;field_name\&quot;, \&quot;direction\&quot;]&#x60;
     * @type {string}
     * @memberof ConsultationrequestsApiGetConsultationAudilogistinfo
     */
    readonly sort?: string

    /**
     * Format: &#x60;[start, end]&#x60;
     * @type {string}
     * @memberof ConsultationrequestsApiGetConsultationAudilogistinfo
     */
    readonly range?: string

    /**
     * Format: &#x60;{\&quot;filter_field\&quot;:\&quot;filter_value\&quot;}&#x60;
     * @type {string}
     * @memberof ConsultationrequestsApiGetConsultationAudilogistinfo
     */
    readonly filter?: string
}

/**
 * Request parameters for getConsultationRequests operation in ConsultationrequestsApi.
 * @export
 * @interface ConsultationrequestsApiGetConsultationRequestsRequest
 */
export interface ConsultationrequestsApiGetConsultationRequestsRequest {
    /**
     * Format: &#x60;[\&quot;field_name\&quot;, \&quot;direction\&quot;]&#x60;
     * @type {string}
     * @memberof ConsultationrequestsApiGetConsultationRequests
     */
    readonly sort?: string

    /**
     * Format: &#x60;[start, end]&#x60;
     * @type {string}
     * @memberof ConsultationrequestsApiGetConsultationRequests
     */
    readonly range?: string

    /**
     * Format: &#x60;{\&quot;filter_field\&quot;:\&quot;filter_value\&quot;}&#x60;
     * @type {string}
     * @memberof ConsultationrequestsApiGetConsultationRequests
     */
    readonly filter?: string
}

/**
 * Request parameters for getConsultationsHistory operation in ConsultationrequestsApi.
 * @export
 * @interface ConsultationrequestsApiGetConsultationsHistoryRequest
 */
export interface ConsultationrequestsApiGetConsultationsHistoryRequest {
    /**
     * 
     * @type {string}
     * @memberof ConsultationrequestsApiGetConsultationsHistory
     */
    readonly fromDate: string

    /**
     * 
     * @type {string}
     * @memberof ConsultationrequestsApiGetConsultationsHistory
     */
    readonly toDate: string

    /**
     * Format: &#x60;[\&quot;field_name\&quot;, \&quot;direction\&quot;]&#x60;
     * @type {string}
     * @memberof ConsultationrequestsApiGetConsultationsHistory
     */
    readonly sort?: string

    /**
     * Format: &#x60;[start, end]&#x60;
     * @type {string}
     * @memberof ConsultationrequestsApiGetConsultationsHistory
     */
    readonly range?: string

    /**
     * Format: &#x60;{\&quot;filter_field\&quot;:\&quot;filter_value\&quot;}&#x60;
     * @type {string}
     * @memberof ConsultationrequestsApiGetConsultationsHistory
     */
    readonly filter?: string
}

/**
 * Request parameters for getConsultationsHistoryCentre operation in ConsultationrequestsApi.
 * @export
 * @interface ConsultationrequestsApiGetConsultationsHistoryCentreRequest
 */
export interface ConsultationrequestsApiGetConsultationsHistoryCentreRequest {
    /**
     * 
     * @type {string}
     * @memberof ConsultationrequestsApiGetConsultationsHistoryCentre
     */
    readonly fromDate: string

    /**
     * 
     * @type {string}
     * @memberof ConsultationrequestsApiGetConsultationsHistoryCentre
     */
    readonly toDate: string

    /**
     * Format: &#x60;[\&quot;field_name\&quot;, \&quot;direction\&quot;]&#x60;
     * @type {string}
     * @memberof ConsultationrequestsApiGetConsultationsHistoryCentre
     */
    readonly sort?: string

    /**
     * Format: &#x60;[start, end]&#x60;
     * @type {string}
     * @memberof ConsultationrequestsApiGetConsultationsHistoryCentre
     */
    readonly range?: string

    /**
     * Format: &#x60;{\&quot;filter_field\&quot;:\&quot;filter_value\&quot;}&#x60;
     * @type {string}
     * @memberof ConsultationrequestsApiGetConsultationsHistoryCentre
     */
    readonly filter?: string
}

/**
 * Request parameters for getRecordings operation in ConsultationrequestsApi.
 * @export
 * @interface ConsultationrequestsApiGetRecordingsRequest
 */
export interface ConsultationrequestsApiGetRecordingsRequest {
    /**
     * 
     * @type {number}
     * @memberof ConsultationrequestsApiGetRecordings
     */
    readonly consultationUid: number
}

/**
 * Request parameters for patientJoinConsultation operation in ConsultationrequestsApi.
 * @export
 * @interface ConsultationrequestsApiPatientJoinConsultationRequest
 */
export interface ConsultationrequestsApiPatientJoinConsultationRequest {
    /**
     * 
     * @type {number}
     * @memberof ConsultationrequestsApiPatientJoinConsultation
     */
    readonly consultationId: number
}

/**
 * Request parameters for updateConsultationPatient operation in ConsultationrequestsApi.
 * @export
 * @interface ConsultationrequestsApiUpdateConsultationPatientRequest
 */
export interface ConsultationrequestsApiUpdateConsultationPatientRequest {
    /**
     * 
     * @type {number}
     * @memberof ConsultationrequestsApiUpdateConsultationPatient
     */
    readonly consultationId: number

    /**
     * 
     * @type {number}
     * @memberof ConsultationrequestsApiUpdateConsultationPatient
     */
    readonly patientId: number
}

/**
 * Request parameters for uploadRecording operation in ConsultationrequestsApi.
 * @export
 * @interface ConsultationrequestsApiUploadRecordingRequest
 */
export interface ConsultationrequestsApiUploadRecordingRequest {
    /**
     * 
     * @type {number}
     * @memberof ConsultationrequestsApiUploadRecording
     */
    readonly consultationUid: number

    /**
     * 
     * @type {any}
     * @memberof ConsultationrequestsApiUploadRecording
     */
    readonly file: any
}

/**
 * ConsultationrequestsApi - object-oriented interface
 * @export
 * @class ConsultationrequestsApi
 * @extends {BaseAPI}
 */
export class ConsultationrequestsApi extends BaseAPI {
    /**
     * 
     * @summary Accept Consultation
     * @param {ConsultationrequestsApiAcceptConsultationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConsultationrequestsApi
     */
    public acceptConsultation(requestParameters: ConsultationrequestsApiAcceptConsultationRequest, options?: AxiosRequestConfig) {
        return ConsultationrequestsApiFp(this.configuration).acceptConsultation(requestParameters.consultationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Cancel Consultation
     * @param {ConsultationrequestsApiCancelConsultationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConsultationrequestsApi
     */
    public cancelConsultation(requestParameters: ConsultationrequestsApiCancelConsultationRequest, options?: AxiosRequestConfig) {
        return ConsultationrequestsApiFp(this.configuration).cancelConsultation(requestParameters.consultationId, requestParameters.cancelledReason, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Close Consultation
     * @param {ConsultationrequestsApiCloseConsultationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConsultationrequestsApi
     */
    public closeConsultation(requestParameters: ConsultationrequestsApiCloseConsultationRequest, options?: AxiosRequestConfig) {
        return ConsultationrequestsApiFp(this.configuration).closeConsultation(requestParameters.testId, requestParameters.remarks, requestParameters.remarksRight, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Close Consultation By Centre
     * @param {ConsultationrequestsApiCloseConsultationByCentreRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConsultationrequestsApi
     */
    public closeConsultationByCentre(requestParameters: ConsultationrequestsApiCloseConsultationByCentreRequest, options?: AxiosRequestConfig) {
        return ConsultationrequestsApiFp(this.configuration).closeConsultationByCentre(requestParameters.consultationId, requestParameters.remarks, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create Consultation
     * @param {ConsultationrequestsApiCreateConsultationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConsultationrequestsApi
     */
    public createConsultation(requestParameters: ConsultationrequestsApiCreateConsultationRequest, options?: AxiosRequestConfig) {
        return ConsultationrequestsApiFp(this.configuration).createConsultation(requestParameters.consultationRequestCreate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Download Recording
     * @param {ConsultationrequestsApiDownloadRecordingRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConsultationrequestsApi
     */
    public downloadRecording(requestParameters: ConsultationrequestsApiDownloadRecordingRequest, options?: AxiosRequestConfig) {
        return ConsultationrequestsApiFp(this.configuration).downloadRecording(requestParameters.consultationUid, requestParameters.filename, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Centre Current Consultation
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConsultationrequestsApi
     */
    public getCentreCurrentConsultation(options?: AxiosRequestConfig) {
        return ConsultationrequestsApiFp(this.configuration).getCentreCurrentConsultation(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Consultation Audilogistinfo
     * @param {ConsultationrequestsApiGetConsultationAudilogistinfoRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConsultationrequestsApi
     */
    public getConsultationAudilogistinfo(requestParameters: ConsultationrequestsApiGetConsultationAudilogistinfoRequest, options?: AxiosRequestConfig) {
        return ConsultationrequestsApiFp(this.configuration).getConsultationAudilogistinfo(requestParameters.consultationId, requestParameters.sort, requestParameters.range, requestParameters.filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Consultationrequests
     * @param {ConsultationrequestsApiGetConsultationRequestsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConsultationrequestsApi
     */
    public getConsultationRequests(requestParameters: ConsultationrequestsApiGetConsultationRequestsRequest = {}, options?: AxiosRequestConfig) {
        return ConsultationrequestsApiFp(this.configuration).getConsultationRequests(requestParameters.sort, requestParameters.range, requestParameters.filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Consultations History
     * @param {ConsultationrequestsApiGetConsultationsHistoryRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConsultationrequestsApi
     */
    public getConsultationsHistory(requestParameters: ConsultationrequestsApiGetConsultationsHistoryRequest, options?: AxiosRequestConfig) {
        return ConsultationrequestsApiFp(this.configuration).getConsultationsHistory(requestParameters.fromDate, requestParameters.toDate, requestParameters.sort, requestParameters.range, requestParameters.filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Consultations History Centre
     * @param {ConsultationrequestsApiGetConsultationsHistoryCentreRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConsultationrequestsApi
     */
    public getConsultationsHistoryCentre(requestParameters: ConsultationrequestsApiGetConsultationsHistoryCentreRequest, options?: AxiosRequestConfig) {
        return ConsultationrequestsApiFp(this.configuration).getConsultationsHistoryCentre(requestParameters.fromDate, requestParameters.toDate, requestParameters.sort, requestParameters.range, requestParameters.filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Current Consultation
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConsultationrequestsApi
     */
    public getCurrentConsultation(options?: AxiosRequestConfig) {
        return ConsultationrequestsApiFp(this.configuration).getCurrentConsultation(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Recordings
     * @param {ConsultationrequestsApiGetRecordingsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConsultationrequestsApi
     */
    public getRecordings(requestParameters: ConsultationrequestsApiGetRecordingsRequest, options?: AxiosRequestConfig) {
        return ConsultationrequestsApiFp(this.configuration).getRecordings(requestParameters.consultationUid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Patient Join Consultation
     * @param {ConsultationrequestsApiPatientJoinConsultationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConsultationrequestsApi
     */
    public patientJoinConsultation(requestParameters: ConsultationrequestsApiPatientJoinConsultationRequest, options?: AxiosRequestConfig) {
        return ConsultationrequestsApiFp(this.configuration).patientJoinConsultation(requestParameters.consultationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Consultation Patient
     * @param {ConsultationrequestsApiUpdateConsultationPatientRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConsultationrequestsApi
     */
    public updateConsultationPatient(requestParameters: ConsultationrequestsApiUpdateConsultationPatientRequest, options?: AxiosRequestConfig) {
        return ConsultationrequestsApiFp(this.configuration).updateConsultationPatient(requestParameters.consultationId, requestParameters.patientId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Upload Recording
     * @param {ConsultationrequestsApiUploadRecordingRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConsultationrequestsApi
     */
    public uploadRecording(requestParameters: ConsultationrequestsApiUploadRecordingRequest, options?: AxiosRequestConfig) {
        return ConsultationrequestsApiFp(this.configuration).uploadRecording(requestParameters.consultationUid, requestParameters.file, options).then((request) => request(this.axios, this.basePath));
    }
}
