// third-party
import { FormattedMessage } from 'react-intl';

// assets
import {
  BuildOutlined,
  CalendarOutlined,
  CustomerServiceFilled,
  FileTextOutlined,
  MessageOutlined,
  ShoppingCartOutlined,
  UserOutlined,
  AppstoreAddOutlined,
  MedicineBoxOutlined,
  UserAddOutlined
} from '@ant-design/icons';

// type
import { NavItemType } from '../types/menu';

// icons
const icons = {
  BuildOutlined,
  CalendarOutlined,
  CustomerServiceFilled,
  MessageOutlined,
  ShoppingCartOutlined,
  UserOutlined,
  AppstoreAddOutlined,
  FileTextOutlined,
  MedicineBoxOutlined,
  UserAddOutlined
};


// hooks -----------------------------------------------
// import useAuth from 'hooks/useAuth';
import { JWTContextType } from 'types/auth';

// ==============================|| MENU ITEMS - APPLICATIONS ||============================== //
const Applications = (user: JWTContextType) => {

  const _children_all = [
    {
      id: 'questionnaire',
      title: <FormattedMessage id="questionnaires" />,
      type: 'collapse',
      icon: icons.MedicineBoxOutlined,
      children: [
        {
          id: 'list',
          title: <FormattedMessage id="questionnaire-list" />,
          type: 'item',
          url: '/apps/questions/list'
        },
      ]
    },
    {
      id: 'centre',
      title: <FormattedMessage id="centre" />,
      type: 'collapse',
      icon: icons.MedicineBoxOutlined,
      children: [
        {
          id: 'list',
          title: <FormattedMessage id="centre-list" />,
          type: 'item',
          url: '/apps/centre/list'
        },
      ]
    },
    {
      id: 'audiologist',
      title: <FormattedMessage id="audiologist" />,
      type: 'collapse',
      icon: icons.MedicineBoxOutlined,
      children: [
        {
          id: 'list',
          title: <FormattedMessage id="audiologist-list" />,
          type: 'item',
          url: '/apps/audiologist/list'
        },
      ]
    },
    {
      id: 'reports',
      title: <FormattedMessage id="reports" />,
      type: 'collapse',
      icon: icons.MedicineBoxOutlined,
      children: [
        {
          id: 'consultation_history',
          title: <FormattedMessage id="consultation_history" />,
          type: 'item',
          url: '/reports/consultations'
        },
      ]
    },

  ];

  const _children_audiologist = [{
    id: 'audiologist',
    title: <FormattedMessage id="audiologist" />,
    type: 'collapse',
    icon: icons.MedicineBoxOutlined,
    children: [
      {
        id: 'list',
        title: <FormattedMessage id="audiologist-list" />,
        type: 'item',
        url: '/apps/audiologist/list'
      },
    ]
  },
  {
    id: 'reports',
    title: <FormattedMessage id="reports" />,
    type: 'collapse',
    icon: icons.MedicineBoxOutlined,
    children: [
      {
        id: 'consultation_history',
        title: <FormattedMessage id="consultation_history" />,
        type: 'item',
        url: '/reports/consultations'
      },
    ]
  }
  ]

  let children: NavItemType[] = [];
  const roles = user.user?.permissions?.split(',') ?? [];
  if (roles.length > 0)
    if (roles.filter(role => role == 'ADMIN').length > 0)
      children = _children_all;
    else if (roles.filter(role => role == 'AUDIOLOGIST').length > 0)
      children = _children_audiologist;

  const appLinks: NavItemType = {
    id: 'group-applications',
    title: <FormattedMessage id="applications" />,
    icon: icons.AppstoreAddOutlined,
    type: 'group',
    children: children
  };



  const dashboardLinks: NavItemType = {
    id: 'group-dashboard',
    title: 'Dashboard',
    type: 'group',
    icon: icons.UserOutlined,
    children: [
      {
        id: 'dashboard',
        title: 'Dashboard',
        type: 'collapse',
        icon: icons.BuildOutlined,
        children: [
          {
            id: 'dashboard',
            title: 'Dashboard',
            type: 'item',
            url: '/dashboard',
            breadcrumbs: false
          },
          // {
          //   id: 'admin',
          //   title: 'Admin',
          //   type: 'item',
          //   url: '/dashboard/admin',
          //   breadcrumbs: false
          // }
        ]
      }
    ]
  };
  return [dashboardLinks, appLinks]
};


export default Applications;
